















import {Vue, Component, Prop, Watch, Emit} from "vue-property-decorator";
import ShowSelDayType from "@/views/Money/components/ShowSelDay/indexType";
import moment from "moment";
import {IndexStore} from "@/store/modules/Index";

@Component({name: "ShowSelDay"})
export default class ShowSelDay extends Vue implements ShowSelDayType{
    show = false
    minDate = new Date(new Date().getFullYear(),1,1)
    maxDate = new Date()
    defaultDate:Date[] = []

    mounted(){
        let List = [];
        List[0] = new Date(moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"))
        List[1] = new Date()
        this.pullData({
            start:moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
            end:moment().format("YYYY-MM-DD HH:mm:ss"),
        })
        this.defaultDate = List
    }

    onConfirm(date:any){
        let data = { start:"",end:"" }
        if ( Array.isArray(date) ){
            date.forEach((item,index)=>{
                if (index) {
                    data.end = moment(new Date(item)).format("YYYY-MM-DD HH:mm:ss")
                }else{
                    data.start = moment(new Date(item)).format("YYYY-MM-DD HH:mm:ss")
                }
            })
        }
        this.pullData( data )
        this.pullShow(false)
    }

    @Prop(Array)
    defaultList!:any[]
    @Watch("defaultList")
    changeDefaultList(newVal:any[]){
        this.defaultDate = newVal
    }

    @Prop(Boolean)
    hide!:boolean;
    @Watch("hide")
    changeHide(newVal:boolean){
        this.show = newVal
        // if ( newVal ){
        //     IndexStore.SetIncidentBool(true)
        // }else{
            // IndexStore.SetIncidentBool(false)
        // }
    }
    @Watch("show")
    changeShow(newVal:boolean){
        this.pullShow(newVal)
    }
    @Emit("pullShow")
    pullShow(data:boolean){
        return data
    }
    @Emit("pullData")
    pullData(data:any){
        return data
    }
}
