









import {Vue, Component} from "vue-property-decorator";
import AwaitDepositType from "@/views/Money/AwaitDeposit/AwaitDeposit";
import ZoomPage from "@/impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue";
import AwaitDepositHead from "@/views/Money/AwaitDeposit/components/Head/index.vue";
import AwaitDepositList from "@/views/Money/AwaitDeposit/components/List/index.vue";
import {AwaitDepositStore} from "@/views/Money/AwaitDeposit/Store";

@Component({name: "AwaitDeposit",components:{ HeadTop,AwaitDepositHead,AwaitDepositList }})
export default class AwaitDeposit extends ZoomPage implements AwaitDepositType{

    activated(){
        let { userId = "" } = this.$route.query
        if ( userId ){
            let upData = AwaitDepositStore.getAwaitDepositUpData;
            upData.userId = userId as string
            AwaitDepositStore.SetAwaitDepositUpData( upData )
        }
    }
}
