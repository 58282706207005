





































import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import AwaitDepositItemType from "@/views/Money/AwaitDeposit/components/Item/indexType";
import moment from "moment";
import {setDepositAudit} from "@/views/Money/AwaitDeposit/Server";
import {AwaitDepositStore} from "@/views/Money/AwaitDeposit/Store";

@Component({name: "AwaitDepositItem"})
export default class AwaitDepositItem extends Vue implements AwaitDepositItemType{

    handleAudit(state:number){
        //detailOrderId
        setDepositAudit(this.getData.detailOrderId,state).then(res=>{
            this.pullRefresh(true)
        })
    }

    get getState(){
        switch ( this.getData && this.getData.auditStatus | 0 || 0 ){
            case 0:
                return "通过";
            case 1:
                return "失败";
            case 2:
                return "审批中";
            case 3:
                return "未审批";
            case 4:
                return "待金额到达";
            case 5:
                return "金额未到达";
        }
        return ""
    }

    get getStateColor(){
        switch ( this.getData && this.getData.auditStatus | 0 || 0 ){
            case 0:
                return "#f2f2f2";
            case 1:
                return "#f45c57";
            case 2:
                return "#fad435";
            case 3:
                return "#fad435";
            case 4:
                return "#f2f2f2";
            case 5:
                return "#f45c57";
        }
        return "#f2f2f2"
    }

    get getTime(){
        return this.getData && moment(this.getData.createTime).format("YYYY-MM-DD HH:mm")
    }
    @Prop(Object)
    data!:any
    get getData(){ return this.data }
    @Prop(Number)
    index!:number
    get getIndex(){ return this.index }

    @Emit("pullRefresh")
    pullRefresh(bool=false){
        let upData = AwaitDepositStore.getAwaitDepositUpData;
        upData.pageNo = 1;
        AwaitDepositStore.SetAwaitDepositUpData(upData)
        return bool
    }
}
