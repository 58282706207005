
















import {Vue, Component, Watch} from "vue-property-decorator";
import AwaitDepositListType from "./indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import AwaitDepositItem from "@/views/Money/AwaitDeposit/components/Item/index.vue";
import {getDepositList} from "@/views/Money/AwaitDeposit/Server";
import {AwaitDepositStore} from "@/views/Money/AwaitDeposit/Store";

@Component({name: "AwaitDeposit",components:{ PullDownUpList,ListBottom,AwaitDepositItem }})
export default class AwaitDepositList extends Vue implements AwaitDepositListType{
    PullDown = false
    StopUp = false
    UpDownBool = false
    List:any[] = []

    activated(){
        this.initData()
    }

    initData(){
        if (this.getList.length) this.List = this.getList;
        else {
            let upData = AwaitDepositStore.getAwaitDepositUpData;
            upData.pageNo = 1;
            AwaitDepositStore.SetAwaitDepositUpData(upData)
            this.UpDownBool = true
            this.handleUpData()
        }
    }

    handlePullDown(): void {
        let upData = AwaitDepositStore.getAwaitDepositUpData;
        upData.pageNo = 1;
        AwaitDepositStore.SetAwaitDepositUpData(upData)
        this.PullDown = true
        this.handleUpData(true)
    }

    handleUpDown(): void {
        if ( this.StopUp )return;
        let upData = AwaitDepositStore.getAwaitDepositUpData;
        upData.pageNo += 1;
        AwaitDepositStore.SetAwaitDepositUpData(upData)
        this.UpDownBool = true
        this.handleUpData()
    }

    handleUpData(bool=false): void {
        getDepositList().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < AwaitDepositStore.getAwaitDepositUpData.pageSize )this.StopUp = true;
            if ( bool )
                AwaitDepositStore.SetAwaitDepositList( res );
            else
                AwaitDepositStore.SetAwaitDepositList( AwaitDepositStore.getAwaitDepositList.concat(res) );
        })
    }

    get getList(){ return AwaitDepositStore.getAwaitDepositList }
    @Watch("getList")
    changeList(newVal:any[]){
        this.List = newVal
    }

    get getNav(){ return AwaitDepositStore.getAwaitDepositUpData }
    @Watch("getNav",{ deep:true })
    changeNav(newVal:any){
        this.handleUpData(true)
    }
}
