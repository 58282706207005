import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import AwaitDepositStoreType from "@/views/Money/AwaitDeposit/Store/indexType";
import {DepositListType} from "@/Api/Money/indexType";

@Module({
    name:"AwaitDeposit",
    store,
    dynamic: true,
})
export default class AwaitDeposit extends VuexModule implements AwaitDepositStoreType{

    AwaitDepositList: any[] = [];
    get getAwaitDepositList(){ return this.AwaitDepositList }
    @Mutation
    SetAwaitDepositList(data: any[]): void {
        this.AwaitDepositList = data
    }


    AwaitDepositUpData: DepositListType = {
        auditStatus: 3, endDate: "", pageNo: 1, pageSize: 15, startDate: "", userId: ""
    };
    get getAwaitDepositUpData(){ return this.AwaitDepositUpData }
    @Mutation
    SetAwaitDepositUpData(data: DepositListType): void {
        this.AwaitDepositUpData = data
    }

}

export const AwaitDepositStore = getModule( AwaitDeposit )
