



























import {Vue, Component, Emit, Watch, Ref} from "vue-property-decorator";
import AwaitDepositHeadType from "./indexType";
import ScrollViewX from "@/components/ShareComponent/ScrollViewX.vue";
import ShowSelDay from "@/views/Money/components/ShowSelDay/index.vue";
import { navList } from '../../Model/index';
import {isStopScroll} from "@/util/Adaptive";
import {AwaitDepositStore} from "@/views/Money/AwaitDeposit/Store";

@Component({name: "AwaitDepositHead",components:{ ScrollViewX,ShowSelDay }})
export default class AwaitDepositHead extends Vue implements AwaitDepositHeadType{
    calendarIcon = require("@/views/Money/Img/calendar.png")
    navList = navList
    navIndex = 0
    endTime = "2021-11-23"
    startTime = "2021-10-23"
    show = false

    @Ref("AwaitDepositBox")
    box!:any

    handleClickNav(index:number){
        if ( index != this.navIndex ){
            this.navIndex = index
            let upData = AwaitDepositStore.getAwaitDepositUpData;
            upData.pageNo = 1
            upData.auditStatus = this.navList[this.navIndex]['state']
            AwaitDepositStore.SetAwaitDepositUpData(upData)
            this.pullData({ data:this.navList[this.navIndex],index })
        }
    }

    handleGetData(e:any){
        this.startTime = e.start.split(" ")[0]
        this.endTime = e.end.split(" ")[0]
    }

    handleGetShow(e:boolean){
        this.show = e
    }

    @Watch("show")
    changeShow(newVal:boolean){
        if ( newVal ){
            let time = setTimeout(()=>{
                let box = this.box.$el.querySelector(".van-calendar__body")
                isStopScroll(box)
                clearTimeout(time)
            })
        }
    }

    @Emit("pullData")
    pullData(data:any){
        return data
    }
}
